var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.interestFollowUp.interestFollowUpImagePath,
            _vm.interestFollowUp.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.interestFollowUp.interestFollowUpImageIsDefault &&
          _vm.checkPrivilege(_vm.hasInterestFollowUpDeleteImage())},on:{"changeValue":function($event){_vm.interestFollowUp.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-replyStatusTypeToken"),"value":_vm.interestFollowUp.replyStatusTypeToken,"options":_vm.replyStatusTypeTokenOptions,"title":_vm.$t('InterestFollowUps.replyStatusType'),"imgName":'type.svg'},on:{"changeValue":function($event){_vm.interestFollowUp.replyStatusTypeToken = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.interestFollowUp.fullCode,"title":_vm.$t('InterestFollowUps.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.interestFollowUp.fullCode = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-interestFollowUpDescriptionAr"),"errors":_vm.errors_interestFollowUpDescriptionAr,"value":_vm.interestFollowUp.interestFollowUpDescriptionAr,"title":_vm.$t('InterestFollowUps.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.interestFollowUp.interestFollowUpDescriptionAr = $event;
            _vm.$v.interestFollowUp.interestFollowUpDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-interestFollowUpDescriptionEn"),"errors":_vm.errors_interestFollowUpDescriptionEn,"value":_vm.interestFollowUp.interestFollowUpDescriptionEn,"title":_vm.$t('InterestFollowUps.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.interestFollowUp.interestFollowUpDescriptionEn = $event;
            _vm.$v.interestFollowUp.interestFollowUpDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-interestFollowUpDescriptionUnd"),"value":_vm.interestFollowUp.interestFollowUpDescriptionUnd,"title":_vm.$t('InterestFollowUps.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.interestFollowUp.interestFollowUpDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-interestFollowUpNotes"),"value":_vm.interestFollowUp.interestFollowUpNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.interestFollowUp.interestFollowUpNotes = $event}}})],1)]),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.submitName)+" ")]),_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"btn btn-cancel",on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }